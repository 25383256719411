import * as React from "react";
import {
  Header,
  Container,
  Title,
  SubTitle,
  Content,
} from "../components/styles";
import Pattern from "../images/home.png";
import { useStaticQuery, graphql, Link } from "gatsby"; // to query for image data
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/seo";

const HeaderCustom = styled((props) => <Header {...props} />)`
  &:after {
    background-image: url(${Pattern});
  }
`;

export const HomeImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  flex-wrap: wrap-reverse;

  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

export const HomeImagesItem = styled((props) => <Img {...props} />)`
  flex: auto;
  margin: 0.5rem;
  width: calc(100% - 1rem);
  flex: auto;

  @media (max-width: 900px) {
    min-width: 150px;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "home" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO title="Celebration Cakes in Rugby" />
      <HeaderCustom>
        <Container>
          <Title>The Stanley Bakes</Title>
        </Container>
      </HeaderCustom>
      <Container>
        <Content>
          <div>
            <p>
              Welcome to The Stanley Bakes. My name is Rachel, and I am a
              part-time baker based in Rugby, Warwickshire. I specialise in
              creating beautifully designed{" "}
              <Link to="/wedding-cakes-rugby/">wedding cakes</Link>, desert
              tables and celebration <Link to="/cakes/">cakes</Link>.
            </p>
            <p>
              The Stanley Bakes was stemmed from a lifelong passion for baking.
              My bakes are all made with the finest quality ingredients to
              ensure they taste delicious. The cakes are hand decorated to
              combine a modern style with classic flavours.
            </p>
            <SubTitle>Wedding Cakes in Rugby</SubTitle>
            <p>
              I personally deliver wedding cakes throughout Rugby, Warwickshire
              and sometimes further afield. Each cake is designed especially for
              you. I offer a personal and professional service from your first
              consultation to the completion of your cakes. Please see my
              wedding cakes page to find your inspiration.
            </p>
            <SubTitle>Tasting Boxes</SubTitle>
            <p>
              Not sure which flavour of cake you'd like to order for your
              celebration cakes or cupcakes? I'd recommend ordering one of my
              tasting boxes. They contain up to four different flavours of cake.
              Delivery of these cake tasting boxes is UK wide so you don't have
              to be local to Rugby to try them. My most popular cake flavours
              include white chocolate &amp; raspberry and salted caramel.
            </p>

            <SubTitle>Cupcakes</SubTitle>
            <p>
              The Stanley Bakes offers boxes of cupcakes in either six or
              twelve. There are also options of larger cupcake packages for
              events or as part of a desert table for your wedding. The cupcakes
              can be decorated in my special buttercream designs, and paired
              with extras such as sprinkles, dried flowers or meringue kisses.
            </p>
            <p>
              Please get in touch to <Link to="/contact/">order</Link> or follow
              me on{" "}
              <a
                href="https://www.instagram.com/thestanleybakes/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>{" "}
              to see the latest creations.
            </p>
          </div>
          <div>
            <HomeImages>
              {data.allFile.edges.map((image) => (
                <HomeImagesItem
                  fluid={image.node.childImageSharp.fluid}
                  alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                  key={image.node.base.split(".")[0]}
                />
              ))}
            </HomeImages>
          </div>
        </Content>
      </Container>
    </React.Fragment>
  );
};

export default IndexPage;
